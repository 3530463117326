import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const addOwnerAccessUrl                    = `/api/admin/ownerAccess/addOwnerAccess`;
const getOwnerAccessByTeamIDUrl            = (teamId) => `/api/admin/ownerAccess/getOwnerAccessByTeamID/${teamId}`;
const deleteOwnerAccessUrl                 = (id) => `/api/admin/ownerAccess/deleteOwnerAccess/${id}`;

export const addOwnerAccess = async (ownerDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        addOwnerAccessUrl,
        { ownerDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getOwnerAccessByTeamID = async (teamId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getOwnerAccessByTeamIDUrl(teamId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteOwnerAccess = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        deleteOwnerAccessUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
