import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from '../components/global/header';
import SideMenu from '../components/global/sideMenu';
import AdminMenu from '../components/settings/adminMenu';
import AdminUser from '../components/settings/adminUser';
import EmailTemplate from '../components/settings/emailTemplate';
import AdminRole from '../components/settings/adminRole';
import Team from '../components/golfClub/team';
import Player from '../components/golfClub/player';
import Category from '../components/golfClub/category';
import Mandatory from '../components/teamSelection/mandatory';
import Draft from '../components/teamSelection/draft';
import IsLive from '../components/teamSelection/IsLive';
import Auction from '../components/teamSelection/auction';
import OwnerAccess from '../components/golfClub/ownerAccess';

const Admin = (props) => {

	const [headerTitle,                   setHeaderTitle]                      = useState('');
    const [menuID,                        setMenuID]                           = useState(null);

    const toggleState = (title, menuID) => {
		setHeaderTitle(title);
        setMenuID(menuID);
	};

    return (  
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <SideMenu menuID={menuID} title={toggleState} />
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                
                <Header onLogout={props.onAuthStateChange} title={headerTitle} /> 
                {menuID && <div style={{ paddingTop: '0px' }} id="kt_content" className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                    <Switch>
                    {/* Team Selection */}

                    <Route path='/mandatory' exact>
                        <Mandatory
                            title={toggleState}
                        />
                    </Route>
                    <Route path='/draft' exact>
                        <Draft
                            title={toggleState}
                        />
                    </Route>
                    <Route path='/auction' exact>
                        <Auction
                            title={toggleState}
                        />
                    </Route>
                    <Route path='/round-live' exact>
                        <IsLive
                            title={toggleState}
                        />
                    </Route>
                    {/* Golf Club */}
                        <Route path='/teams' exact>
                            <Team
                                title={toggleState}
                            />
                        </Route>
                        <Route path='/owner-access' exact>
                            <OwnerAccess
                                title={toggleState}
                            />
                        </Route>
                        <Route path='/player' exact>
                            <Player
                                title={toggleState}
                            />
                        </Route>
                        <Route path='/category' exact>
                            <Category
                                title={toggleState}
                            />
                        </Route>
                    {/* Settings */}
                        <Route path="/admin-user" exact>
                            <AdminUser
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-role" exact>
                            <AdminRole
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-menu" exact>
                            <AdminMenu
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/email-template" exact>
                            <EmailTemplate
                                title={toggleState} 
                            />
                        </Route>
                    {/* Settings End*/}
                        <Redirect to={'/' + menuID} />
                    
                    </Switch>
                </div>}
            </div>
            
        </div>
    );
};

export default withRouter(Admin);