import { useEffect, useState } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import { deleteUnSoldPlayerByID, getActiveIsLiveForRandomPlayer, getAuctionPlayer, getInProcessPlayer, getRandomPlayer, getSoldPlayer, getUnSoldPlayer, updateAuctionPlayer } from "../api/auctionAPI";
import { getTeam } from "../api/golfClubAPI";
import Player from "../golfClub/player";
import Loading from "../global/loading";
import AddAuctionPlayerModal from "./addAuctionPlayerModal";

const Auction = (props) => {

    const [navState,                 setNavState]                  = useState('Auction');
    const [player,                   setPlayer]                    = useState(null)
    const [selectedTeam,             setSelectedTeam]              = useState(null);
    const [teamList,                 setTeamList]                  = useState([]);
    const [allTeamList,              setAllTeamList]               = useState([]);
    const [playerDetails,            setPlayerDetails]             = useState({Amount: 100000})
    const [playerList,               setPlayerList]                = useState([]);
    const [filteredSoldPlayerList,   setFilteredSoldPlayerList]    = useState([]);
    const [unSoldPlayerList,         setUnSoldPlayerList]          = useState([]);
    const [loading,                  setLoading]                   = useState(false);
    const [unSoldLoading,            setUnSoldLoading]             = useState(false);
    const [showPlayerModal,          setShowPlayerModal]           = useState(null);
    const [filterTeam,               setFilterTeam]                = useState(null);
    const [selectedCategory,         setSelectedCategory]          = useState(1);
    const [isLive,                   setIsLive]                    = useState();

    useEffect(() => {
        props.title("Auction", "auction")  
        fetchInProcessPlayer() 
        // fetchActiveIsLiveForRandomPlayer()
        fetchTeam();
    }, []);

    useEffect(() => {
        if (playerList) {
            if (filterTeam && filterTeam.ID == 0) {
                setFilteredSoldPlayerList([...playerList])
            } else {
                const players = playerList.filter(p => p.TeamID == filterTeam.ID)
                setFilteredSoldPlayerList(players)
            }
        }
    }, [playerList, filterTeam])

    useEffect(() => {
        if (navState == 'Sold Player') {
            fetchSoldPlayer()
            setSelectedTeam(null)
            setShowPlayerModal(null)
            setFilterTeam(allTeamList[0])
        } else if (navState == 'UnSold Player') {
            fetchUnSoldPlayer()
            setSelectedTeam(null)
            setShowPlayerModal(null)
            setFilterTeam(allTeamList[0])
        } else {
            // fetchActiveIsLiveForRandomPlayer()
            fetchInProcessPlayer() 
        }
    }, [navState])

    const getPlayerHandler = async () => {
        try {
            const response = await getRandomPlayer();
            setPlayer(response)
        } catch (err) {              
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const fetchInProcessPlayer = async () => {
        try {
            const response = await getInProcessPlayer()
            if (response) setPlayer(response)
        } catch (err) {              
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const fetchActiveIsLiveForRandomPlayer = async () => {
        try {
            const response = await getActiveIsLiveForRandomPlayer()
            setIsLive(response)
        } catch (err) {              
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const fetchTeam = async () => {
        try {
            const response = await getTeam();
            const teams = [{ID: 0, Name: 'All', DisplayName: 'All'}, ...response]
            setFilterTeam(teams[0])
            setAllTeamList(teams)
            setTeamList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const AddAuctionPlayerHandler = async (from) => {
        try {
            if (from == 'sold' && !selectedTeam) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Warning!',
                    text                  : "Select Team",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                return;
            }

            const swalResult = await swal.fire({
                title               : 'Are you sure?',
                // text                : `You won't be able to revert this!`,
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : `${from == 'sold' ? 'Sold' : 'Unsold'}`,
                preConfirm: async () => {
                    try {
                        await updateAuctionPlayer({
                            ...playerDetails, 
                            IsBroadCast : true,
                            PlayerID    : player.PlayerID, 
                            From        : from, 
                            TeamID      : selectedTeam ? selectedTeam.ID : 0, 
                            CategoryID  : player.CategoryID
                        })
                        setPlayer(null)
                        setPlayerDetails({Amount: 100000})
                        setSelectedTeam(null)
                        fetchInProcessPlayer()
                        swal.fire({
                            icon: 'success',
                            titleText: `Player ${from == 'sold' ? "Sold" : "Unsold"}!`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
        } catch (err) {              
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const fetchSoldPlayer = async () => {
        try {
            setLoading(true)
            const response = await getSoldPlayer()
            setPlayerList(response);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchUnSoldPlayer = async () => {
        try {
            setUnSoldLoading(true)
            const response = await getUnSoldPlayer()
            setUnSoldPlayerList(response);
            setUnSoldLoading(false)
        } catch (error) {
            setUnSoldLoading(false)
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const deleteHandler = async (ID, PlayerID) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            // text                : `You won't be able to revert this!`,
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : `delete`,
            preConfirm: async () => {
                try {
                    await deleteUnSoldPlayerByID(ID, PlayerID)
                    fetchUnSoldPlayer()
                    swal.fire({
                        icon: 'success',
                        titleText: `Player deleted!`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });
                    return;
                }
            },
        });
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={navState === "Auction" ? "top-nav-active" : "" } onClick={() => setNavState("Auction")}  >  
                                    Auction
                                </a>
                                <a className={navState === "Sold Player" ? "top-nav-active" : "" } onClick={() => setNavState("Sold Player")}  >  
                                    Sold Player
                                </a>
                                <a className={navState === "UnSold Player" ? "top-nav-active" : "" } onClick={() => setNavState("UnSold Player")}  >  
                                    Unsold Player
                                </a>
                            </nav>
                        </div>
                        {/* <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <div className = "kt-portlet__head_subhead" >

                                        <span className="margin-r10">
                                            Category : <strong>{!isLive ? 'No Category is Live' : isLive.Round == 1 ? 'Gold' : isLive.Round == 2 ? 'Silver' : isLive.Round == 3 ? 'Bronze' : 'Other Player'}</strong>
                                        </span>
                                        {isLive &&
                                            <span className="margin-r10">
                                                Players Left : <strong>{}</strong>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit padding-20">
                        {navState == 'Auction' && (
                            <>
                                {player ? (
                                    <>
                                        <table id="table" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th>Player</th>
                                                    <th className="text-center">Category</th>
                                                    <th className="text-center">Handicap</th>
                                                    <th className="text-center">Base Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td title={player.DisplayName}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                {(player.Photo && player.Photo != '') ? <img src={player.Photo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{player.DisplayName.substring(0, 1)}</div>}
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip">{player.DisplayName}</span>
                                                                <span className="kt-user-card-v2__desc text-clip">{player.MemberShipNo}</span>
                                                            </div>
                                                        </div>      
                                                    </td>
                                                    <td className="text-center">
                                                        <span className={`kt-badge kt-badge--${player.CategoryName == 'Gold' ? 'gold' : player.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width`}>
                                                            {player.CategoryName}
                                                        </span>
                                                    </td>
                                                    <td className="text-center">{player.Handicap}</td>
                                                    <td className="text-center"><i className="la la-rupee"></i>100000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="form-group row">
                                            <div className="col-5">
                                                <Select
                                                    value={selectedTeam && {label: selectedTeam.DisplayName, value: selectedTeam}}
                                                    onChange={(event) => setSelectedTeam(event.value)}
                                                    name="ddlTeam"
                                                    placeholder="Select Team"
                                                    id="ddlTeam"
                                                    className="ddlTeam"
                                                    options={teamList.map(c => ({
                                                        label: `${c.DisplayName}`,
                                                        value: c
                                                    }))}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    id="amount"
                                                    name="amount"
                                                    type='number'
                                                    placeholder="Amount"
                                                    className = "form-control"
                                                    value={playerDetails.Amount || ''}
                                                    onChange ={({target}) => setPlayerDetails(prev => ({...prev, 'Amount': target.value}))}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <button
                                                    id="add-team-btn"
                                                    className="btn btn-success margin-r20"
                                                    style={{ maxWidth: '70%' }}
                                                    type="button"
                                                    onClick={() => AddAuctionPlayerHandler('sold')}
                                                >
                                                    Sold
                                                </button>
                                                <button
                                                    id="add-team-btn"
                                                    className="btn btn-warning margin-0"
                                                    style={{ maxWidth: '70%' }}
                                                    type="button"
                                                    onClick={() => AddAuctionPlayerHandler('unsold')}
                                                >
                                                    unsold
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <button type="button" className="btn btn-primary margin-t0" onClick={() => getPlayerHandler()}>Get Player</button>
                                    </>
                                )}
                            </>
                        )}
                        {navState == 'Sold Player' && (
                            <>
                                <div className="form-group row">
                                    <div className="col-5">
                                        <Select
                                            value={filterTeam && {label: filterTeam.DisplayName, value: filterTeam}}
                                            onChange={(event) => setFilterTeam(event.value)}
                                            name="ddlTeam"
                                            placeholder="Select Team"
                                            id="ddlTeam"
                                            className="ddlTeam"
                                            options={allTeamList.map(c => ({
                                                label: `${c.DisplayName}`,
                                                value: c
                                            }))}
                                        />
                                    </div>
                                </div>
                                {loading ? <Loading/> : (
                                    <table id="table-draft-pick" className="table table-bordered table-hover margin-b30">
                                        <thead className="light-grey-background">
                                            <tr>
                                                <th>Player</th>
                                                <th>Team</th>
                                                <th className="text-center">Category</th>
                                                <th className="text-center">Handicap</th>
                                                <th className="text-center">Amount</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredSoldPlayerList.length > 0 ? (
                                                filteredSoldPlayerList.map((p) => (
                                                    <tr className={`clickable`} key={p.ID} data-id={p.ID}>
                                                        <td title={p.DisplayName}>
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    {(p.Photo && p.Photo != '') ? <img src={p.Photo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{p.DisplayName.substring(0, 1)}</div>}
                                                                </div>
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                    <span className="kt-user-card-v2__desc text-clip">{p.MemberShipNo}</span>
                                                                </div>
                                                            </div>      
                                                        </td>
                                                        <td title={p.TeamName}>
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    {(p.Logo && p.Logo != '') ? <img src={p.Logo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{p.TeamName.substring(0, 1)}</div>}
                                                                </div>
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip">{p.TeamName}</span>
                                                                </div>
                                                            </div>      
                                                        </td>
                                                        <td className="text-center">
                                                            <span className={`kt-badge kt-badge--${p.CategoryName == 'Gold' ? 'gold' : p.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width`}>
                                                                {p.CategoryName}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">{p.Handicap}</td>
                                                        <td className="text-center"><i className="la la-rupee"></i>{p.Amount}</td>
                                                        <td className="text-right padding-r20">
                                                            <button 
                                                                type="button"                    
                                                                onClick={() => setShowPlayerModal(p)}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                <i className="flaticon2-edit"></i>
                                                            </button>                             
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan={6}>No Player Found</td>
                                                </tr>
                                            )}
                                        </tbody>    
                                    </table>
                                )}
                            </>
                        )}
                        {navState == 'UnSold Player' && (
                            <>
                                {unSoldLoading ? <Loading/> : (
                                    <table id="table-draft-pick" className="table table-bordered table-hover margin-b30">
                                        <thead className="light-grey-background">
                                            <tr>
                                                <th>Player</th>
                                                <th className="text-center">Category</th>
                                                <th className="text-center">Handicap</th>
                                                <th className="text-center">Base Price</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {unSoldPlayerList.length > 0 ? (
                                                unSoldPlayerList.map((p) => (
                                                    <tr className={`clickable`} key={p.ID} data-id={p.ID}>
                                                        <td title={p.DisplayName}>
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    {(p.Photo && p.Photo != '') ? <img src={p.Photo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{p.DisplayName.substring(0, 1)}</div>}
                                                                </div>
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                    <span className="kt-user-card-v2__desc text-clip">{p.MemberShipNo}</span>
                                                                </div>
                                                            </div>      
                                                        </td>
                                                        <td className="text-center">
                                                            <span className={`kt-badge kt-badge--${p.CategoryName == 'Gold' ? 'gold' : p.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width`}>
                                                                {p.CategoryName}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">{p.Handicap}</td>
                                                        <td className="text-center"><i className="la la-rupee"></i>100000</td>
                                                        <td className="text-center">
                                                            <button 
                                                                type="button"                    
                                                                onClick={() => setShowPlayerModal(p)}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                <i className="flaticon2-edit"></i>
                                                            </button>                           
                                                            <button 
                                                                type="button"                    
                                                                onClick={() => deleteHandler(p.AuctionPlayerID, p.ID)}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                            </button>                            
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan={6}>No Player Found</td>
                                                </tr>
                                            )}
                                        </tbody>    
                                    </table>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showPlayerModal && (
                <AddAuctionPlayerModal
                    show={showPlayerModal}
                    // setRefresh={setRefresh}
                    teamList={teamList}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    playerDetails={playerDetails}
                    setPlayerDetails={setPlayerDetails}
                    fetchSoldPlayer={() => fetchSoldPlayer()}
                    fetchUnSoldPlayer={() => fetchUnSoldPlayer()}
                    onDismissModal={() => setShowPlayerModal(null)}
                />
            )}
        </>
    )
}

export default Auction