import React, { useState, useEffect } from 'react';
import bannerLogo from '../../assets/images/PGL_Login_logo.png';
import '../../assets/styles/login.css';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { deviceDetect } from "react-device-detect";
import { postLogin, requestLoginOTP } from '../api/loginAPI';
import { setAuthState } from '../../assets/scripts/login-util';
import { checkVersion } from '../../assets/scripts/versionComparer';

const Login = (props) => {

    const [loginData,           setLoginData]           = useState({ remember: false });
    const [timer,               setTimer]               = useState('');
    const [resend,              setResend]              = useState('Please Wait');
    const [otpSent,             setOtpSent]             = useState(false);
    const [otpIntervalId,       setOtpIntervalId]       = useState(null);
    const [resendIntervalId,    setResendIntervalId]    = useState(null);

    const loginDataHandler = (key, value)=>{
        setLoginData((d)=> { return {...d, [key] : value} });
    };

    const requestOTPHandler = async (e) => {
        e.preventDefault();

        const btn = $('#btnGetOTP');
        var form = $('#kt_login_signin');
        form.validate().destroy();

        form.validate({
            rules: {
                txtEmail: {
                    required: true,
                    email: true,
                }
            },
            messages: {
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                }
            }
        });

        if (!form.valid()) return;
 
        helper.StartProcessing(btn);

        try {  
            await requestLoginOTP(loginData.email);
            otpTimer();  
            setOtpSent(true);       
            helper.StopProcessing(btn);
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: "btn btn-brand",
              })
            return; 
        }

    };   

    const resendOTPHandler = async (e) => {
        e.preventDefault();

        if (resendIntervalId) return;

        clearInterval(otpIntervalId);
        clearInterval(resendIntervalId);
        loginDataHandler('otp', '');

        swal.fire({
            titleText: 'Loading',
            text: 'Please Wait...',
            showConfirmButton: false,
            onOpen: () => {
                swal.showLoading();
            }
        });

        try { 
            await requestLoginOTP(loginData.email);
            swal.close();
            otpTimer(); 
        } catch (err) {
            swal.close();
            swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: "btn btn-brand",
              })
            return; 
        }
        
    };   

    const loginHandler = async (e) => {
        e.preventDefault();

        const btn = $('#btnLogin');
        var form = $('#kt_login_signin');
        form.validate().destroy();

        form.validate({
            rules: {
                txtOTP: {
                    required: true,
                    number: true,
                    minlength: 6,
                    maxlength: 6,
                }
            },
            messages: {
                txtOTP: {
                    required: "Please enter OTP"
                }
            }
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);

        try {
            const deviceData = deviceDetect();
            const device = await helper.getDeviceDetails(deviceData)
            const params = await postLogin(loginData.email, loginData.otp, loginData.remember, "N", device);

            setAuthState(params, loginData.remember);
            window.cookieChangeHandler();
            checkVersion(params.FE_VERSION);
            clearInterval(otpIntervalId);
            clearInterval(resendIntervalId);
            props.onLogin();
            window.location.reload();
            
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon              : "error",
                titleText         : "Error!",
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: "btn btn-brand",
              })
            return;  
        }

    };

    const otpTimer = () => {
        let t = 180;
        let resendTime = 20;

        const otpInterval = setInterval(() => {
            if (t > 0) {
                var d = (t % (60 * 60));
                var ds = Math.ceil(d % 60);
                var ms = { m: Math.floor(d / 60), s: ds < 10 ? `0${ds}` : ds };
                t = t - 1;
                setTimer(`${ms.m}:${ms.s}`);
            } else {
                t = 0;
                if (otpInterval) clearInterval(otpInterval);
                setOtpIntervalId(null);
                setTimer('Otp Expired');
            }
        }, 1000)

        const resendInterval = setInterval(() => {
            if (resendTime > 0) {
                resendTime = resendTime - 1;
                setResend(`Please wait for ${resendTime} seconds to Resend OTP`);
            } else {
                resendTime = 0;
                if (resendInterval) clearInterval(resendInterval);
                setResendIntervalId(null);
                setResend('Resend OTP');
            }
        }, 1000)

        setOtpIntervalId(otpInterval);
        setResendIntervalId(resendInterval);

    };  

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3" id="kt_login">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{ backgroundColor: 'rgb(247, 248, 250)' }}>
                <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                    <div className="kt-login__container">
                        <div className="kt-login__logo">
                            <img height="110" src={bannerLogo} alt="logo" />
                        </div>
                        <div className="kt-login__signin">
                            <div className="kt-login__head">
                                <h3 className="kt-login__title">Admin Login</h3>
                                {otpSent && (
                                    <h4 id="otpTimer" className="text-center margin-t10">{timer}</h4>
                                )}
                            </div>
                            <form className="kt-form" id="kt_login_signin">
                                <div id="ControlHolder">
                                    <div className="form-group">
                                        <input 
                                            onChange={({ target }) => loginDataHandler('email', target.value)}
                                            value={ loginData.email || '' }
                                            className="form-control form-control-lg" 
                                            type="email" 
                                            disabled={otpSent}
                                            placeholder="Email" 
                                            id="txtEmail" 
                                            name="txtEmail" 
                                            autoComplete="off" 
                                        />
                                    </div>
                                    {otpSent && (
                                        <div className="form-group">
                                            <input 
                                                onChange={({ target }) => loginDataHandler('otp', target.value)}
                                                value={ loginData.otp || '' }
                                                className="form-control form-control-lg" 
                                                type="text" 
                                                placeholder="OTP" 
                                                id="txtOTP" 
                                                name="txtOTP" 
                                            />
                                        </div>
                                    )}
                                    <div className="form-group margin-t20 ">
                                        <label className="kt-checkbox kt-checkbox--tick kt-checkbox--blue">
                                            <input 
                                                id="chkRemember" 
                                                type="checkbox" 
                                                name="chkRemember" 
                                                defaultChecked={ loginData.remember ? "checked" : "" }
                                                onChange={() => loginDataHandler("remember", !loginData.remember)}
                                            /> 
                                            Remember Me
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="kt-login__actions">
                                    {otpSent ? (
                                        <>
                                            <button 
                                                id="btnLogin" 
                                                type="button" 
                                                disabled={otpIntervalId == null}
                                                onClick={loginHandler}
                                                className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"> Login 
                                            </button>
                                            <div id="resendOtpDiv" className="row margin-t20">
                                                <div className="col text-center">
                                                    <span className="kt-login__account-msg">Didn't get it ?</span>&nbsp;&nbsp;
                                                    <span id="kt_login_otp_resend" onClick={resendOTPHandler} className="kt-login__account-link clickable">{resend}</span>
                                                </div>
                                            </div>
                                        </>                                   
                                    ):(
                                        <button 
                                            id="btnGetOTP" 
                                            type="button" 
                                            onClick={requestOTPHandler}
                                            className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"> Request OTP 
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;