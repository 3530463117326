import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { updateAuctionPlayer } from '../api/auctionAPI';
import swal from 'sweetalert2';

const AddAuctionPlayerModal = ({ 
        show, 
        teamList, 
        onDismissModal, 
        selectedTeam,
        setSelectedTeam,
        playerDetails,
        setPlayerDetails,
        fetchSoldPlayer,
        fetchUnSoldPlayer
    }) => {

    useEffect(() => {
        $('#player-modal').modal({
            backdrop: 'static',
        });
        $('#player-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            // props.setRefresh(false)
            onDismissModal();
            setPlayerDetails({Amount: 100000})
            setSelectedTeam(null)
        });
        if (show.TeamID != 0) {
            setSelectedTeam({ID: show.TeamID, DisplayName: show.TeamName})
            setPlayerDetails({Amount: show.Amount})
        }
    }, [show]);

    const dismissModal = () => {
        
    }

    const AddAuctionPlayerHandler = async (from) => {
        try {
            if (from == 'sold' && !selectedTeam) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Warning!',
                    text                  : "Select Team",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                return;
            }
            const swalResult = await swal.fire({
                title               : 'Are you sure?',
                // text                : `You won't be able to revert this!`,
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : `${from == 'sold' ? 'Sold' : 'Unsold'}`,
                preConfirm: async () => {
                    try {
                        await updateAuctionPlayer({
                            ...playerDetails, 
                            IsBroadCast : false,
                            PlayerID    : show.ID, 
                            From        : from, 
                            TeamID      : selectedTeam ? selectedTeam.ID : 0, 
                            CategoryID  : show.CategoryID
                        })
                        setPlayerDetails({Amount: 100000})
                        setSelectedTeam(null)
                        if (show.TeamID != 0) {
                            fetchSoldPlayer()
                        } else {
                            fetchUnSoldPlayer()
                        }
                        $('#player-modal').modal('hide');  
                        onDismissModal()
                        swal.fire({
                            icon: 'success',
                            titleText: `Player ${from == 'sold' ? "Sold" : "Unsold"}!`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
        } catch (err) {              
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }
    return (
        <>
            <div
                className="modal fade"
                id="player-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> Player </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='modal-body'>
                            <div className="form-group row">
                                <div className="col-5">
                                    <Select
                                        value={selectedTeam && {label: selectedTeam.DisplayName, value: selectedTeam}}
                                        onChange={(event) => setSelectedTeam(event.value)}
                                        name="ddlTeam"
                                        placeholder="Select Team"
                                        id="ddlTeam"
                                        className="ddlTeam"
                                        options={teamList.map(c => ({
                                            label: `${c.DisplayName}`,
                                            value: c
                                        }))}
                                    />
                                </div>
                                <div className="col-2">
                                    <input
                                        id="amount"
                                        name="amount"
                                        type='number'
                                        placeholder="Amount"
                                        className = "form-control"
                                        value={playerDetails.Amount || ''}
                                        onChange ={({target}) => setPlayerDetails(prev => ({...prev, 'Amount': target.value}))}
                                    />
                                </div>
                                <div className='col-4'>
                                    <button
                                        id="add-team-btn"
                                        className="btn btn-success margin-r20"
                                        style={{ maxWidth: '70%' }}
                                        type="button"
                                        onClick={() => AddAuctionPlayerHandler('sold')}
                                    >
                                        Sold
                                    </button>
                                    {show.TeamID != 0 && (
                                        <button
                                            id="add-team-btn"
                                            className="btn btn-warning margin-0"
                                            style={{ maxWidth: '70%' }}
                                            type="button"
                                            onClick={() => AddAuctionPlayerHandler('unsold')}
                                        >
                                            unsold
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">
                                Close
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAuctionPlayerModal