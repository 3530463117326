import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getInProcessPlayerUrl     = '/api/admin/auction/getInProcessPlayer';
const getUnSoldPlayerUrl        = '/api/admin/auction/getUnSoldPlayer';
const getSoldPlayerUrl          = '/api/admin/auction/getSoldPlayer';
const getRandomPlayerUrl        = `/api/admin/auction/getRandomPlayer`;
const updateAuctionPlayerUrl    = `/api/admin/auction/updateAuctionPlayer`;
const getActiveIsLiveForRandomPlayerUrl    = `/api/admin/auction/getActiveIsLiveForRandomPlayer`;

const deleteUnSoldPlayerByIDUrl = (id, playerID) => `/api/admin/auction/deleteUnSoldPlayerByID/${id}/${playerID}`;

export const deleteUnSoldPlayerByID = async (id, playerID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        deleteUnSoldPlayerByIDUrl(id, playerID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getInProcessPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getInProcessPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getUnSoldPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getUnSoldPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSoldPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getSoldPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getRandomPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getRandomPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getActiveIsLiveForRandomPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getActiveIsLiveForRandomPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateAuctionPlayer = async (teamDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        updateAuctionPlayerUrl,
        { teamDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};