import React from 'react'

const ShowPlayer = (props) => {
    return (
        <div className={`kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0`}>
            <table className="kt-datatable__table table-striped">
                <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                    <tr className="kt-datatable__row block-row">
                        <td className="kt-datatable__cell text-clip">
                            <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__pic">
                                    {(props.player.Photo && props.player.Photo != '') ?
                                        <img src={props.player.Photo} height="40" width="40" alt="banner-image" /> : 
                                        <div className="kt-badge kt-badge--xl kt-badge--success">{props.player.DisplayName.substring(0, 1)}</div>
                                    }
                                </div>
                                <div className="kt-user-card-v2__details text-clip">
                                    <span className="kt-user-card-v2__name text-clip"> {props.player.DisplayName} </span>
                                    <span className="kt-user-card-v2__desc text-clip">Handicap: {props.player.Handicap} </span>
                                </div>
                            </div>
                        </td>
                        <td className="kt-datatable__cell text-clip">
                            <i className="la la-rupee"></i>{props.player.Amount}
                        </td>
                        <td className="text-right padding-r20">
                            {props.player.CategoryName &&
                                <span className={`kt-badge kt-badge--${props.player.CategoryName == 'Gold' ? 'gold' : props.player.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width margin-r50` }>
                                    {props.player.CategoryName}
                                </span>
                            }
                            <button 
                                type="button"                    
                                onClick={() => props.deleteHandler()}
                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                            </button>                           
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ShowPlayer