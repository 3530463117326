import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const requestLoginOTPUrl                                 = (email) => `/api/admin/admin/requestLoginOTP/${email}`;
const postLoginUrl                                       = (email) => `/api/admin/admin/login/${email}`;
const logoutUrl                                          = '/api/admin/admin/logout';


export const requestLoginOTP = async (email) => {    
    const response = await axiosGet(requestLoginOTPUrl(email), { headers: ''});
    return response;
}

export const postLogin = async (email, otp, remember, isApp, device) => {    
    const response = await axiosPost(postLoginUrl(email), { otp, remember, isApp, device }, { headers: ''});
    return response;
}

export const logout = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosDelete(logoutUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
}