import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getTeam } from "../api/golfClubAPI";
import swal from 'sweetalert2';
import Select from 'react-select';
import Loading from '../global/loading';
import { addDraftSequence, deleteDraftIndex, getDraftTeamByRound, postDraftIndex, updateDraftSequence } from "../api/draftAPI";
import * as helper from '../global/helper';
import PlayerModal from "./playerModal";

const Draft = (props) => {

    const [navState,                    setNavState]                    = useState('Draft Sequence');
    const [teamList,                    setTeamList]                    = useState([]);
    const [filteredTeamList,            setFilteredTeamList]            = useState([]);
    const [draftTeamList,               setDraftTeamList]               = useState([]);
    const [roundType,                   setRoundType]                   = useState('1')
    const [draftPickRoundType,          setDraftPickRoundType]          = useState('1')
    const [teamDraftDetails,            setTeamDraftDetails]            = useState({})
    const [loading,                     setLoading]                     = useState(false)
    const [editDraft,                   setEditDraft]                   = useState(false);
    const [selectedTeam,                setSelectedTeam]                = useState(null);
    const [showPlayerModal,             setShowPlayerModal]             = useState(null);
    const [refresh,                     setRefresh]                     = useState(false);

    useEffect(() => {
        props.title("Draft", "draft")   
        fetchTeam()
    }, []);

    useEffect(() => {
        if(refresh) 
            fetchDraftTeam()
    }, [refresh])

    useEffect(() => {
        if (roundType) {
            AllowSortable();
            fetchDraftTeam()
        }
        if (roundType && (teamList && teamList.length > 0)) {
            if (roundType == '1') {
                setFilteredTeamList(teamList);
                setSelectedTeam(null)
                setTeamDraftDetails({})
            } else {
                const updatedTeamList = teamList.filter(p => p.IsOwnerPlaying == 'N');
                setFilteredTeamList(updatedTeamList)
                setSelectedTeam(null)
                setTeamDraftDetails({})
            }
        }
    }, [roundType, teamList])

    useEffect(() => {
        if(draftPickRoundType) {
            fetchDraftTeam()
        }
    }, [draftPickRoundType])

    useEffect(() => {
        if(navState) {
            fetchDraftTeam()
            setRoundType('1')
            setDraftPickRoundType('1')
        }
    }, [navState])

    const updateTeamDraftDetails = (field, value) => {
        setTeamDraftDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchTeam = async () => {
        try {
            const response = await getTeam()
            setTeamList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchDraftTeam = async () => {
        try {
            setLoading(true)
            const response = await getDraftTeamByRound(navState == 'Draft Pick' ? draftPickRoundType : roundType)
            setDraftTeamList(response); 
            setRefresh(false)
            setLoading(false)            
        } catch (error) {
            setLoading(false)            
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const DeleteDraftHandler = async (draft) => {
        try {   
            await deleteDraftIndex({DraftSequenceID: draft.ID, roundType, DraftIndexID: draft.DraftIndexID})
            fetchDraftTeam()
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Team deleted!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const AddDraftTeamHandler = async () => {
        const btn = $('#add-team-btn');
        var form = $('#add-team-form');

        form.validate({
            rules: {
                ddlTeam: {
                    required: true
                },          
                txtIndex: {
                    required: true,
                    number: true
                },          
            },
            messages: {
                ddlTeam: {
                    required: "Please select team"
                },
                txtIndex: {
                    required: "Please enter index"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);
        try {
            if (roundType == 1 || roundType == 7) {
                await postDraftIndex({...teamDraftDetails, TeamID: selectedTeam.ID, Round: roundType})
            } else {
                await addDraftSequence({...teamDraftDetails, DraftIndex: teamDraftDetails.Index, Round: roundType})
            }
            // await postDraftIndex({...teamDraftDetails, TeamID: selectedTeam.ID, TeamType: teamType});
            helper.StopProcessing(btn);
            setSelectedTeam(null)
            setTeamDraftDetails({})
            // setEditDraft(false)
            fetchDraftTeam()
            if (teamDraftDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Team updated!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Team added!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    //Sorting -->
    const AllowSortable = () => {
        if (roundType != '7' && roundType != '1') {
            const config = (event, ui) => {
                const ids = [];
                $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
                sortAndSave(ids);   
            }
            window.sortInit('#table-block tbody', config);    
        }
    }

    const sortAndSave = async (param) => {
        if(param.length <= 0) return;     
        
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });

            let templates = [];
            setDraftTeamList(d => {
                const sorted = [];
                if(d.length > 0){                  
                    for (const [i,id] of Object.entries(param)) {                       
                        let f = d.find( a => a.ID == id);
                        if(!!f){
                            f.Index = parseInt(i)+1;                    
                            sorted.push(f);
                        }
                    }
                
                    templates = sorted;
                    return d;
                }
            })

            if(templates.length > 0){                 
                const res = await updateDraftSequence(templates);
                fetchDraftTeam()
            }        
            swal.close()
        } catch (err) {              
            swal.close()
            swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;  
        }
    }

    const showPlayerModalHandler = (p) => {
        const prevRound = draftTeamList.find(d => d.Index == p.Index - 1)
        const nxtRound = draftTeamList.find(d => d.Index == p.Index + 1)
        let allowPlayerToAdd = true
        let allowPlayerToDelete = true

        if (nxtRound && nxtRound.PlayerID != 0) {
            allowPlayerToDelete = false
        }


        if (p.Index != 1) {
            if (prevRound && prevRound.PlayerID == 0) {
                allowPlayerToAdd = false
            }
        }
        if(p.DisplayName != 'TBA') {
            setShowPlayerModal({...p, allowPlayerToAdd, allowPlayerToDelete})
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={navState === "Draft Sequence" ? "top-nav-active" : "" } onClick={() => setNavState("Draft Sequence")}  >  
                                    Draft Sequence
                                </a>
                                <a className={navState === "Draft Pick" ? "top-nav-active" : "" } onClick={() => setNavState("Draft Pick")}  >  
                                    Draft Pick
                                </a>
                            </nav>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit padding-20">
                        {navState == 'Draft Sequence' && (
                            <>    
                                <form className="kt-form kt-form--label-right" id="add-team-form" onSubmit={e => e.preventDefault()}>
                                    <div className="form-group row">
                                        <div className="col-3">
                                            <select 
                                                className="form-control kt-selectpicker"
                                                value={roundType || '1'}
                                                onChange={(e) => setRoundType(e.target.value)}
                                            >
                                                <option value='1'>Round 1</option>
                                                <option value='2'>Round 2</option>
                                                <option value='3'>Round 3</option>
                                                <option value='4'>Round 4</option>
                                                <option value='5'>Round 5</option>
                                                <option value='6'>Round 6</option>
                                                <option value='7'>Round 7</option>
                                            </select>
                                        </div>
                                        {(roundType == '1' || roundType == '7') && (
                                            <div className="col-5">
                                                <Select
                                                    value={selectedTeam && {label: selectedTeam.DisplayName, value: selectedTeam}}
                                                    onChange={(event) => setSelectedTeam(event.value)}
                                                    name="ddlTeam"
                                                    placeholder="Select Team"
                                                    id="ddlTeam"
                                                    className="ddlTeam"
                                                    options={filteredTeamList.map(c => ({
                                                        label: `${c.DisplayName}`,
                                                        value: c
                                                    }))}
                                                />
                                            </div>
                                        )}
                                        <div className="col-2">
                                            <input
                                                id="txtIndex"
                                                name="txtIndex"
                                                type='number'
                                                placeholder="Order"
                                                className = "form-control"
                                                value={teamDraftDetails.Index || ''}
                                                onChange ={({target}) => updateTeamDraftDetails('Index', target.value)}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            {editDraft ? (
                                                <div className="btn-group btn-block" role="group">
                                                    <button
                                                        id="add-team-btn"
                                                        className="btn btn-brand btn-block margin-0"
                                                        style={{ maxWidth: '70%' }}
                                                        onClick={AddDraftTeamHandler}
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        style={{ maxWidth: '30%' }}
                                                        className="btn btn-secondary btn-block margin-0 padding-0 margin-r20"
                                                        onClick={() => {
                                                            setTeamDraftDetails({});
                                                            setSelectedTeam(null)
                                                            setEditDraft(false)
                                                        }}
                                                    >
                                                        <i
                                                            className="la la-times"
                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                        />
                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="btn btn-brand margin-l0" id='add-team-btn' onClick={AddDraftTeamHandler} >
                                                    <i className="la la-plus"></i>
                                                    Add
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                                <table id="table-block" className="table table-bordered table-hover margin-b30">
                                    <thead className="light-grey-background">
                                        <tr>
                                            <th>Teams</th>
                                            <th className="text-center">Order</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {loading ? <Loading/> : ( */}
                                        {draftTeamList.length > 0 ? (
                                            draftTeamList.map((p) => (
                                                <tr className={`${(roundType != '7' && roundType != '1') ? 'dragableRow' : ''}`} id='drag' key={p.ID} data-id={p.ID}>
                                                    <td title={p.DisplayName} width="80%">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                {(p.Logo && p.Logo != '') ? <img src={p.Logo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--info" style={{backgroundColor: p.HighColor && p.HighColor}}>{p.DisplayName.substring(0, 1)}</div>}
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip"> {p.DisplayName} </span>
                                                            </div>
                                                        </div>      
                                                    </td>
                                                    <td title={p.DraftIndex} width="10%" className="text-clip text-center">
                                                        <div className="kt-user-card-v2" style={{ justifyContent: 'center' }}>
                                                            <div className="kt-user-card-v2__pic">
                                                                <div className="kt-badge kt-badge--xl kt-badge--info">{p.DraftIndex}</div>
                                                            </div>
                                                        </div>                                                    
                                                    </td>
                                                    <td className="text-clip text-center" width="10%">
                                                        {/* <> */}
                                                            <button
                                                                type="button"
                                                                onClick={() => DeleteDraftHandler(p)}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover"
                                                                data-placement="left"
                                                                data-content="Delete File">
                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                            </button>
                                                        {/* </> */}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4}> 
                                                    <div className="padding-10"> 
                                                        <h5 className="text-center text-muted"> Teams Not Found...! </h5> 
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {/* )} */}
                                    </tbody>
                                </table>
                                
                            </>
                        )}
                        {navState == 'Draft Pick' && (
                            <>
                                <form className="kt-form kt-form--label-right" id="add-team-form" onSubmit={e => e.preventDefault()}>
                                    <div className="form-group row">
                                        <div className="col-3">
                                            <select 
                                                className="form-control kt-selectpicker"
                                                value={draftPickRoundType || '1'}
                                                onChange={(e) => setDraftPickRoundType(e.target.value)}
                                            >
                                                <option value='1'>Round 1</option>
                                                <option value='2'>Round 2</option>
                                                <option value='3'>Round 3</option>
                                                <option value='4'>Round 4</option>
                                                <option value='5'>Round 5</option>
                                                <option value='6'>Round 6</option>
                                                <option value='7'>Round 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <table id="table-draft-pick" className="table table-bordered table-hover margin-b30">
                                    <thead className="light-grey-background">
                                        <tr>
                                            <th>Teams</th>
                                            <th>Player</th>
                                            <th className="text-center">Order</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {draftTeamList.length > 0 ? (
                                            draftTeamList.map((p) => (
                                                <tr className={`clickable`} id='drag' key={p.ID} data-id={p.ID} onClick={() => showPlayerModalHandler(p)}>
                                                    <td title={p.DisplayName} width="60%">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                {(p.Logo && p.Logo != '') ? <img src={p.Logo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--info" style={{backgroundColor: p.HighColor && p.HighColor}}>{p.DisplayName.substring(0, 1)}</div>}
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip"> {p.DisplayName} </span>
                                                            </div>
                                                        </div>      
                                                    </td>
                                                    {p.PlayerName != '--' ? (
                                                        <td title={p.PlayerName} width="30%">
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    {(p.Photo && p.Photo != '') ? <img src={p.Photo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{p.PlayerName.substring(0, 1)}</div>}
                                                                </div>
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip"> {p.PlayerName} </span>
                                                                </div>
                                                            </div>      
                                                        </td>
                                                    ) : (
                                                        <td width="30%">
                                                            <span>{p.PlayerName}</span> 
                                                        </td>
                                                    )}
                                                    <td title={p.DraftIndex} width="10%" className="text-clip text-center">
                                                        <div className="kt-user-card-v2" style={{ justifyContent: 'center' }}>
                                                            <div className="kt-user-card-v2__pic">
                                                                <div className="kt-badge kt-badge--xl kt-badge--info">{p.DraftIndex}</div>
                                                            </div>
                                                        </div>                                                    
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4}> 
                                                    <div className="padding-10"> 
                                                        <h5 className="text-center text-muted"> Teams Not Found...! </h5> 
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                            
                        )}
                    </div>
                </div>
            </div>
            {showPlayerModal && (
                <PlayerModal
                    show={showPlayerModal}
                    round={draftPickRoundType}
                    setRefresh={setRefresh}
                    onDismissModal={() => setShowPlayerModal(null)}
                />
            )}
        </>
    )
}

export default Draft