import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getIsLiveUrl                                    = '/api/admin/teamSelection/getIsLive';
const getUnAssignedPlayersUrl                         = '/api/admin/teamSelection/getUnAssignedPlayers';
const addOwnerUrl                                     = (id, teamId, ownerAmount) => `/api/admin/teamSelection/addOwner/${id}/${teamId}/${ownerAmount}`;
const getOwnerByTeamIDUrl                             = (teamId) => `/api/admin/teamSelection/getOwnerByTeamID/${teamId}`;
const deleteOwnerUrl                                  = (id) => `/api/admin/teamSelection/deleteOwner/${id}`;

const addCaptainUrl                                   = (id, teamId) => `/api/admin/teamSelection/addCaptain/${id}/${teamId}`;
const getCaptainByTeamIDUrl                           = (teamId) => `/api/admin/teamSelection/getCaptainByTeamID/${teamId}`;
const deleteCaptainUrl                                = (id) => `/api/admin/teamSelection/deleteCaptain/${id}`;

const addPlayerTeamUrl                                = (id, teamId, selectionType, selectionRound, playerAmount) => `/api/admin/teamSelection/addPlayerTeam/${id}/${teamId}/${selectionType}/${selectionRound}/${playerAmount}`;
const getPlayerByTeamIDAndSelectionTypeUrl            = (teamId, selectionType) => `/api/admin/teamSelection/getPlayerByTeamIDAndSelectionType/${teamId}/${selectionType}`;
const deletePlayerUrl                                 = (id, round) => `/api/admin/teamSelection/deletePlayer/${id}/${round}`;
const getPlayerByTeamIDAndRoundUrl                    = (teamID, round) => `/api/admin/teamSelection/getPlayerByTeamIDAndRound/${teamID}/${round}`;
const postIsLiveUrl                                   = (id, isLive) => `/api/admin/teamSelection/postIsLive/${id}/${isLive}`;

export const getUnAssignedPlayers = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getUnAssignedPlayersUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getIsLive = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getIsLiveUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addOwner = async (id, teamId, ownerAmount) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        addOwnerUrl(id, teamId, ownerAmount),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getOwnerByTeamID = async (teamId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getOwnerByTeamIDUrl(teamId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteOwner = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        deleteOwnerUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addCaptain = async (id, teamId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        addCaptainUrl(id, teamId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCaptainByTeamID = async (teamId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCaptainByTeamIDUrl(teamId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteCaptain = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        deleteCaptainUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addPlayerTeam = async (id, teamId, selectionType, selectionRound, playerAmount) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        addPlayerTeamUrl(id, teamId, selectionType, selectionRound, playerAmount),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPlayerByTeamIDAndRound = async (teamID, round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getPlayerByTeamIDAndRoundUrl(teamID, round),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPlayerByTeamIDAndSelectionType = async (teamId, selectionType) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getPlayerByTeamIDAndSelectionTypeUrl(teamId, selectionType),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deletePlayer = async (id, round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        deletePlayerUrl(id, round),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postIsLive = async (id, isLive) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        postIsLiveUrl(id, isLive),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};