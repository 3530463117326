import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getCategory, getTeam, getTeamBySelectionType } from "../api/golfClubAPI";
import Select from 'react-select';
import { addCaptain, addOwner, addPlayerTeam, deleteCaptain, deleteOwner, deletePlayer, getCaptainByTeamID, getOwnerByTeamID, getPlayerByTeamIDAndSelectionType, getUnAssignedPlayers } from "../api/teamSelectionAPI";
import ShowPlayer from "./ShowPlayer";

const Mandatory = (props) => {

    const [teamList,            setTeamList]            = useState({});
    const [selectedTeam,        setSelectedTeam]        = useState(null);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [playerList,          setPlayerList]          = useState([]);
    const [selectedOwner,       setSelectedOwner]       = useState(null);
    const [ownerAmount,         setOwnerAmount]         = useState(100000);
    const [playerAmount,        setPlayerAmount]        = useState(100000);
    const [selectedCaptain,     setSelectedCaptain]     = useState(null);
    const [selectedPlayer,      setSelectedPlayer]      = useState(null);
    const [owner,               setOwner]               = useState([]);
    const [captain,             setCaptain]             = useState(null);
    const [category,            setCategory]            = useState([]);
    const [teamPlayers,         setTeamPlayers]         = useState([]);
    const [allTeamPlayers,      setAllTeamPlayers]      = useState([]);
    const [selectedCategory,    setSelectedCategory]    = useState(null);
    const [filteredPlayers,     setFilteredPlayer]      = useState([]);
    const [refresh,             setRefresh]             = useState(false)

    useEffect(() => {
        props.title("Draft", "mandatory")   
        fetchTeamBySelectionType();
    }, []);

    useEffect(() => {
        if (selectedCategory && (playerList && playerList.length > 0)) {
            const updatedPlayer = playerList.filter(p => p.CategoryID == selectedCategory)
            setFilteredPlayer(updatedPlayer);
            setSelectedPlayer(null)
        }
    }, [selectedCategory, playerList])

    useEffect(() => {
        if (category && category.length > 0) {
            setSelectedCategory(category[0].ID)
        }
    }, [category])

    useEffect(() => {  
        if (rightSideDetails) {
            Config.Core.OpenDrawer();
        } else {
            setSelectedTeam(null);
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if (owner || captain) {
            fetchUnAssignedPlayers()
        }
    }, [owner, captain])

    useEffect(() => {
        if(selectedTeam) {
            fetchUnAssignedPlayers();
            fetchOwnerByTeamID();
            fetchCategory();
            fetchCaptainByTeamID();
            fetchPlayerByTeamID();
        } else {
            setTeamPlayers([])
            setAllTeamPlayers([])
            setPlayerList([])
        }
    }, [selectedTeam])

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [teamList]);

    const fetchTeamBySelectionType = async() => {
        try {
            const response = await getTeamBySelectionType('Mandatory')
            isInit ? setIsInit(false) : $('#tblTeam').KTDatatable().destroy(); 
            setTeamList(response);    
            setRefresh(false)
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fillDataTable = () => {
        const dataTable = $('#tblTeam').KTDatatable({
            data: {
                saveState: false,
                source: teamList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'DisplayName',
                    title: 'Teams',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" style="background-color: ${row.HighColor}">  ${row.DisplayName.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.DisplayName} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'PlayerCount',
                    title: 'Player Selected',
                    template: function (row) { 
                        // const tem = `<span class="kt-badge kt-badge--success kt-badge--inline">${row.PlayerCount} / ${row.IsOwnerPlaying === 'N' ? '5' : '6'}</span>`
                        // return tem
                        const temp = `<span class="font-weight500 text-center">${row.PlayerCount} / ${row.IsOwnerPlaying === 'N' ? '5' : '7'}</span>`
                        return temp
                    },
                    width: 120,
                },
                {
                    field: 'IsOwnerPlaying',
                    title: 'Owner Playing',
                    template: function (row) {
                        if (row.IsOwnerPlaying == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 100,
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTeam .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setSelectedTeam(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setSelectedOwner(null)
            setOwnerAmount(100000)
            setSelectedPlayer(null)
            setPlayerAmount(100000)
            setRightSideDetails(false);
        }, 200);
    }

    const fetchUnAssignedPlayers = async() => {
        try {
            const players = await getUnAssignedPlayers()
            setPlayerList(players);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchPlayerByTeamID = async() => {
        try {
            const { players, response } = await getPlayerByTeamIDAndSelectionType(selectedTeam.ID, 'Mandatory')
            setTeamPlayers(players);             
            setAllTeamPlayers(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchOwnerByTeamID = async() => {
        try {
            const owner = await getOwnerByTeamID(selectedTeam.ID)
            setOwner(owner);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchCaptainByTeamID = async() => {
        try {
            const captain = await getCaptainByTeamID(selectedTeam.ID)
            setCaptain(captain);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchCategory = async() => {
        try {
            const response = await getCategory()
            setCategory(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const AddOwnerHandler = async () => {
        const btn = $('#add-owner-btn');
        var form = $('#add-owner-form');

        form.validate({
            rules: {
                ddlOwnerMenu: {
                    required: true
                },          
            },
            messages: {
                ddlOwnerMenu: {
                    required: "Please select Owner"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await addOwner(selectedOwner, selectedTeam.ID, ownerAmount);
            helper.StopProcessing(btn);
            setSelectedOwner(null)
            fetchOwnerByTeamID();
            setSelectedTeam(prev => ({...prev, PlayerCount:prev.PlayerCount + 1}))
            fetchTeamBySelectionType()
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Owner added!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }
      
    const AddPlayerHandler = async () => {
        const btn = $('#add-player-btn');
        var form = $('#add-player-form');
        form.validate({
            rules: {
                ddlPlayerMenu: {
                    required: true
                },          
            },
            messages: {
                ddlPlayerMenu: {
                    required: "Please select Player"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);
        const playerToAdd = filteredPlayers.find(f => f.ID == selectedPlayer)

        // const categoryLength = teamPlayers.filter(t => t.CategoryID == 2 || t.CategoryID == 3)
        // if ((playerToAdd.CategoryID == 2 || playerToAdd.CategoryID == 3) && (categoryLength.length >= 4)) {
        //     swal.fire({
        //         icon                  : 'warning',
        //         titleText             : 'Warning!',
        //         text                  : "Category Already Added",
        //         buttonsStyling        : false,
        //         confirmButtonClass    : 'btn btn-brand',
        //     });
        //     helper.StopProcessing(btn);
        //     return;
        // }

        if (playerToAdd.CategoryID == 1 && teamPlayers.find(t => t.CategoryID == 1)) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Warning!',
                text                  : "Category Already Added",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
            return;
        }
        AddPlayer()
        async function AddPlayer () {
            try {
                await addPlayerTeam(selectedPlayer, selectedTeam.ID, "Mandatory", 0, playerAmount);
                helper.StopProcessing(btn);
                setSelectedPlayer(null);
                setPlayerAmount(100000);
                fetchUnAssignedPlayers();
                fetchPlayerByTeamID();
                setSelectedTeam(prev => ({...prev, PlayerCount: prev.PlayerCount + 1}))
                fetchTeamBySelectionType()
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Player added!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } catch (err) {
                helper.StopProcessing(btn);
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : err.message,
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                return;
            }
        }
    }

    const AddCaptainHandler = async () => {
        const btn = $('#add-captain-btn');
        var form = $('#add-captain-form');

        form.validate({
            rules: {
                ddlCaptainMenu: {
                    required: true
                },          
            },
            messages: {
                ddlCaptainMenu: {
                    required: "Please select Captain"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await addCaptain(selectedCaptain, selectedTeam.ID);
            helper.StopProcessing(btn);
            setSelectedCaptain(null)
            fetchCaptainByTeamID();
            setSelectedTeam(prev => ({...prev, PlayerCount:prev.PlayerCount + 1}))
            fetchTeamBySelectionType()
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Captain added!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    const DeleteOwnerHandler = async (ID) => {
        try {
            await deleteOwner(ID);
            fetchOwnerByTeamID();
            fetchUnAssignedPlayers();
            setSelectedTeam(prev => ({...prev, PlayerCount:prev.PlayerCount - 1}))
            fetchTeamBySelectionType()
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Owner deleted!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            // helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    const DeletePlayerHandler = async (ID) => {
        try {
            await deletePlayer(ID);
            fetchPlayerByTeamID();
            fetchUnAssignedPlayers();
            setSelectedTeam(prev => ({...prev, PlayerCount:prev.PlayerCount - 1}))
            fetchTeamBySelectionType()
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Player deleted!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            // helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const DeleteCaptainHandler = async () => {
        try {
            await deleteCaptain(captain.ID);
            fetchCaptainByTeamID();
            fetchUnAssignedPlayers();
            setSelectedTeam(prev => ({...prev, PlayerCount:prev.PlayerCount - 1}))
            fetchTeamBySelectionType()
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Captain deleted!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Tournament..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Tournament</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblTeam" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="Draft Picks" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body">
                                    <div className="kt-widget kt-widget--user-profile-3">
                                        <div className="kt-widget__top">
                                            {selectedTeam.Logo ? (
                                                <img className="kt-widget__pic kt-widget__pic" src={selectedTeam.Logo} alt="user-profile"/>
                                            ) : (

                                                <div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest" style={{backgroundColor: selectedTeam.HighColor}}>
                                                    {selectedTeam.DisplayName && selectedTeam.DisplayName.slice(0,1)}
                                                </div>
                                            )}
                                            <div className="kt-widget__content">
                                                <div className="row align-items-center">
                                                    <div className="col-5">
                                                        <div className="kt-widget__head">
                                                            <span className="kt-widget__username">
                                                                {selectedTeam.DisplayName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="font-weight500">Player Selected : {selectedTeam.PlayerCount}</span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className='font-weight500'>Owner Playing</span> : <span className={`kt-badge kt-badge--${selectedTeam.IsOwnerPlaying == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{selectedTeam.IsOwnerPlaying == 'Y' ? 'Yes' : 'No'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet kt-prtlet--mobile">
                                <div className="kt-portlet__body padding-30">
                                    {(selectedTeam.IsOwnerPlaying === 'Y') && (
                                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                            <div className="card">
                                                <div className="card-header" id="menuHeading">
                                                    <div className="card-title collapsed" data-toggle="collapse" data-target='#Owner' aria-expanded="false" aria-controls='Owner'>
                                                        <div className="col-10">
                                                            <span className="margin-l10">Owner</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id='Owner' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                    <div className="card-body">
                                                        <div className="kt-widget4">
                                                            <form className="kt-form kt-form--label-right" id="add-owner-form" onSubmit={e => e.preventDefault()}>
                                                                <div className="form-group row">
                                                                <div className="col-5">
                                                                    <Select
                                                                        value={(playerList && playerList.length > 0 && selectedOwner) && {label: playerList.find(m => m.ID == selectedOwner).DisplayName, value: selectedOwner}}
                                                                        onChange={(event) => setSelectedOwner(event.value)}
                                                                        name="ddlOwnerMenu"
                                                                        placeholder="Select Owner"
                                                                        id="ddlOwnerMenu"
                                                                        className="ddlOwnerMenu"
                                                                        options={playerList.map(c => ({
                                                                            label: `${c.DisplayName} (${c.Handicap})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        id="amount"
                                                                        name="amount"
                                                                        type='number'
                                                                        placeholder="Amount"
                                                                        className = "form-control"
                                                                        value={ownerAmount}
                                                                        onChange ={({target}) => setOwnerAmount(target.value)}
                                                                    />
                                                                </div>
                                                                <div className='col-4'>
                                                                    <button className="btn btn-brand margin-l0" id='add-owner-btn' onClick={AddOwnerHandler} >
                                                                        <i className="la la-plus"></i>
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            </div>
                                                                {/* {owner && (
                                                                    <ShowPlayer
                                                                        player={owner}
                                                                        deleteHandler={DeleteOwnerHandler}
                                                                    />
                                                                )} */}

                                                                {(owner && owner.length > 0) && (
                                                                    owner.map(t => (
                                                                        <ShowPlayer
                                                                            key={t.ID}
                                                                            player={t}
                                                                            deleteHandler={() => DeleteOwnerHandler(t.ID)}
                                                                        />
                                                                    ))
                                                                )}
                                                            </form>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                        <div className="card">
                                            <div className="card-header" id="menuHeading">
                                                <div className="card-title collapsed" data-toggle="collapse" data-target='#Captain' aria-expanded="false" aria-controls='Captain'>
                                                    <div className="col-10">
                                                        <span className="margin-l10">Captain</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='Captain' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                <div className="card-body">
                                                    <div className="kt-widget4">
                                                        <form className="kt-form kt-form--label-right" id="add-captain-form" onSubmit={e => e.preventDefault()}>
                                                            {!captain && (
                                                                <div className="form-group row">
                                                                    <div className="col-7">
                                                                        <Select
                                                                            value={(playerList && playerList.length > 0 && selectedCaptain) && {label: playerList.find(m => m.ID == selectedCaptain).DisplayName, value: selectedCaptain}}
                                                                            onChange={(event) => setSelectedCaptain(event.value)}
                                                                            name="ddlCaptainMenu"
                                                                            placeholder="Select Captain"
                                                                            id="ddlCaptainMenu"
                                                                            className="ddlCaptainMenu"
                                                                            options={playerList.map(c => ({
                                                                                label: `${c.DisplayName} (${c.Handicap})`,
                                                                                value: c.ID
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <button className="btn btn-brand margin-l0" id='add-captain-btn' onClick={AddCaptainHandler} >
                                                                            <i className="la la-plus"></i>
                                                                            Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {captain && (
                                                                <ShowPlayer
                                                                    player={captain}
                                                                    deleteHandler={DeleteCaptainHandler}
                                                                />
                                                            )}
                                                        </form>
                                                    </div>
                                                </div>    
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                        <div className="card">
                                            <div className="card-header" id="menuHeading">
                                                <div className="card-title collapsed" data-toggle="collapse" data-target='#Players' aria-expanded="false" aria-controls='Players'>
                                                    <div className="col-10">
                                                        <span className="margin-l10">Players</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='Players' className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                <div className="card-body">
                                                    <div className="kt-widget4">
                                                        <form className="kt-form kt-form--label-right" id="add-player-form" onSubmit={e => e.preventDefault()}>
                                                            {(teamPlayers && teamPlayers.length < 6) && (
                                                                <div className="form-group row">
                                                                    <div className="col-3">
                                                                        {(category && category.length > 0) && (
                                                                            <select 
                                                                                className="form-control kt-selectpicker"
                                                                                value={selectedCategory || ''}
                                                                                onChange={(e) => setSelectedCategory(e.target.value)}
                                                                            >
                                                                                {category.map(c => (
                                                                                    <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                                                ))}
                                                                            </select>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <Select
                                                                            value={(filteredPlayers && filteredPlayers.length > 0 && selectedPlayer) && {label: filteredPlayers.find(m => m.ID == selectedPlayer).DisplayName, value: selectedPlayer}}
                                                                            onChange={(event) => setSelectedPlayer(event.value)}
                                                                            name="ddlPlayerMenu"
                                                                            placeholder="Select Player"
                                                                            id="ddlPlayerMenu"
                                                                            className="ddlPlayerMenu"
                                                                            options={filteredPlayers.map(c => ({
                                                                                label: `${c.DisplayName} (${c.Handicap})`,
                                                                                value: c.ID
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <input
                                                                            id="playerAmount"
                                                                            name="playerAmount"
                                                                            type='number'
                                                                            placeholder="Amount"
                                                                            className = "form-control"
                                                                            value={playerAmount}
                                                                            onChange ={({target}) => setPlayerAmount(target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <button className="btn btn-brand margin-l0" id='add-player-btn' onClick={AddPlayerHandler} >
                                                                            <i className="la la-plus"></i>
                                                                            Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {(teamPlayers && teamPlayers.length > 0) && (
                                                                teamPlayers.map(t => (
                                                                    <ShowPlayer
                                                                        key={t.ID}
                                                                        player={t}
                                                                        deleteHandler={() => DeletePlayerHandler(t.ID)}
                                                                    />
                                                                ))
                                                            )}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mandatory