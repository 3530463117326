import React, { useEffect, useState } from 'react'
import { addPlayerTeam, deletePlayer, getPlayerByTeamIDAndRound, getPlayerByTeamIDAndSelectionType, getUnAssignedPlayers } from '../api/teamSelectionAPI';
import Select from 'react-select';
import { getCategory } from '../api/golfClubAPI';
import * as helper from '../global/helper';
import ShowPlayer from './ShowPlayer';
import swal from 'sweetalert2';

const PlayerModal = (props) => {

    const [playerList,          setPlayerList]          = useState([]);
    const [selectedPlayer,      setSelectedPlayer]      = useState(null);
    const [category,            setCategory]            = useState([]);
    const [selectedCategory,    setSelectedCategory]    = useState(null);
    const [filteredPlayers,     setFilteredPlayer]      = useState([]);
    const [player,              setPlayer]              = useState(null);

    useEffect(() => {
        $('#player-modal').modal({
          backdrop: 'static',
        });
        $('#player-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.setRefresh(false)
            props.onDismissModal();
        });
        if(props.show) {
            fetchCategory();
            fetchUnAssignedPlayers();
            fetchPlayerByRound();
        }
    }, [props.show]);

    useEffect(() => {
        if (selectedCategory && (playerList && playerList.length > 0)) {
            const updatedPlayer = playerList.filter(p => p.CategoryID == selectedCategory)
            setFilteredPlayer(updatedPlayer);
            setSelectedPlayer(null)
        }
    }, [selectedCategory, playerList])

    useEffect(() => {
        if (category && category.length > 0) {
            if(props.round == 3 || props.round == 4 )
                setSelectedCategory(category[1].ID)
            else
                setSelectedCategory(category[0].ID)
        }
    }, [category])


    const fetchUnAssignedPlayers = async () => {
        try {
            const players = await getUnAssignedPlayers()
            setPlayerList(players);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchCategory = async() => {
        try {
            const response = await getCategory()
            const cat = response.filter(r => (r.ID != 1))
            setCategory(cat);           
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchPlayerByRound = async() => {
        try {
            const response = await getPlayerByTeamIDAndRound(props.show.TeamID, props.round)
            setPlayer(response);           
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const DeletePlayerHandler = async (player) => {
        try {
            if (!props.show.allowPlayerToDelete) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Warning!',
                    text                  : "Delete players in orders",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                return;
            }
            await deletePlayer(player.ID, player.SelectionRound);
            fetchPlayerByRound();
            fetchUnAssignedPlayers()
            props.setRefresh(true)
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Player deleted!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const AddPlayerHandler = async () => {
        const btn = $('#add-player-btn');
        var form = $('#add-player-form');

        form.validate({
            rules: {
                ddlPlayerMenu: {
                    required: true
                },          
            },
            messages: {
                ddlPlayerMenu: {
                    required: "Please select Player"
                }
            }
        });

        if (!form.valid()) return;

        if (!props.show.allowPlayerToAdd) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Warning!',
                text                  : "Add players in orders",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
            return;
        }
        const players = await getPlayerByTeamIDAndSelectionType(props.show.TeamID, 'Draft')
        let categoryBCount = 0;
        let categoryCCount = 0;
        for (const p of players) {
            if (p.CategoryID == 2) {
                categoryBCount += 1
            } else if (p.CategoryID == 3) {
                categoryCCount += 1
            }
        }

        if((selectedCategory == 2 && categoryBCount >= 4)) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Category Already Added",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
            return;
        }

        if((selectedCategory == 3 && categoryCCount >= 4)) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : "Category Already Added",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
            return;
        }
        try {
            helper.StartProcessing(btn);
            await addPlayerTeam(selectedPlayer.ID, props.show.TeamID, "Draft", props.round);
            fetchPlayerByRound()
            props.setRefresh(true)
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Player added!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    return (
        <>
            <div
                className="modal fade"
                id="player-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__pic">
                                    {(props.show.Logo && props.show.Logo != '') ? <img src={props.show.Logo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--info" style={{backgroundColor: props.show.HighColor && props.show.HighColor}}>{props.show.DisplayName.substring(0, 1)}</div>}
                                </div>
                                <div className="kt-user-card-v2__details text-clip">
                                    <span className="kt-user-card-v2__name text-clip"> {props.show.DisplayName} </span>
                                </div>
                            </div>
                            {/* <h5 className="modal-title">Players</h5> */}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"                               
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            {!player ? (
                                <form id="add-player-form" onSubmit={(e) => {e.preventDefault()}}>
                                    <div className="form-group row">
                                        <div className="col-3">
                                            {(category && category.length > 0) && (
                                                <select 
                                                    className="form-control kt-selectpicker"
                                                    value={selectedCategory || ''}
                                                    disabled={props.round == 5 || props.round == 6 ? false : true}
                                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                                >
                                                    {category.map(c => (
                                                        <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                        <div className="col-7">
                                            <Select
                                                value={selectedPlayer && {label: selectedPlayer.DisplayName, value: selectedPlayer}}
                                                onChange={(event) => setSelectedPlayer(event.value)}
                                                name="ddlPlayerMenu"
                                                placeholder="Select Player"
                                                id="ddlPlayerMenu"
                                                className="ddlPlayerMenu"
                                                options={filteredPlayers.map(c => ({
                                                    label: `${c.DisplayName} (${c.Handicap})`,
                                                    value: c
                                                }))}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <button className="btn btn-brand margin-l0" id='add-player-btn' onClick={AddPlayerHandler} >
                                                <i className="la la-plus"></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <ShowPlayer
                                    player={player}
                                    deleteHandler={() => DeletePlayerHandler(player)}
                                />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">
                                Close
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlayerModal