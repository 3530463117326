import React, {useEffect, useState} from 'react'
import { getIsLive, postIsLive } from '../api/teamSelectionAPI';
import swal from 'sweetalert2';

const IsLive = (props) => {

    // const [draftRound,          setDraftRound]          = useState([]);
    const [auction,             setAuction]             = useState(null)
    const [allRound,            setAllRound]            = useState([])

    useEffect(() => {
        props.title("Round Live", "round-live")   
        fetchIsLive();
    }, []);

    const fetchIsLive = async() => {
        try {
            const response = await getIsLive()
            setAllRound(response)
            // setDraftRound(response.slice(0, 7));   
            const category = response.map(r => ({
                ...r,
                Name: r.Round == 1 ? 'Gold' : r.Round == 2 ? 'Silver' : r.Round == 3 ? 'Bronze' : 'Other Player'
            }))          
            setAuction(category);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const roundHandler = async (id, value) => {
        if (value && allRound.find(r => r.IsLive == 'Y')) {
            swal.fire({
                icon: "warning", titleText: 'Oops..', buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                titleText: 'Oops..', text: 'Rounds are already live..!',
            });
            return;
        }
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            // text                : `You won't be able to revert this!`,
            text                : `You ${value ? 'want to Live this!' : 'want off this!'} `,
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : `${value ? 'Live' : 'Off'}`,
            preConfirm: async () => {
                try {
                    const isLive = value ? 'Y' : 'N'
                    await postIsLive(id, isLive);
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });
                    return;
                }
            },
        });
        fetchIsLive()
    }

    return (
        <>
            <div className="kt-portlet">
                <div className="kt-portlet__body">
                    {/* <table id="tblRound" className="table table-bordered table-hover margin-b30">
                        <thead className="light-grey-background">
                            <tr>
                                <th>Draft Round</th>
                                <th>Live </th>
                            </tr>
                        </thead>
                        <tbody>
                            {draftRound && draftRound.length > 0 && (
                                draftRound.map((r, i) => (
                                    <tr key={i} >
                                        <td title={r.DraftIndex}className="text-clip">
                                            <div className="kt-user-card-v2">
                                                <div className="kt-user-card-v2__pic">
                                                    <div className="kt-badge kt-badge--xl kt-badge--info">{r.Round}</div>
                                                </div>
                                            </div>                                                    
                                        </td>
                                        <td>
                                            <span className="kt-switch kt-switch--icon"> 
                                                <label> 
                                                    <input 
                                                        className="chkIsLive" 
                                                        name="chkIsLive" 
                                                        type="checkbox" 
                                                        checked={r.IsLive == 'Y'}
                                                        onChange={({target}) => roundHandler(r.ID, target.checked)}
                                                    />
                                                    <span></span> 
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table> */}
                    <table id="tblRound" className="table table-bordered table-hover margin-b30">
                        <thead className="light-grey-background">
                            <tr>
                                <th>Auction</th>
                                <th>Live </th>
                            </tr>
                        </thead>
                        <tbody>
                            {auction && auction.length > 0 && (
                                auction.map((r, i) => (
                                    <tr key={i} >
                                        <td>{r.Name}</td>
                                        <td>
                                            <span className="kt-switch kt-switch--icon"> 
                                                <label> 
                                                    <input 
                                                        className="chkIsLive" 
                                                        name="chkIsLive" 
                                                        type="checkbox" 
                                                        checked={r.IsLive == 'Y'}
                                                        onChange={({target}) => roundHandler(r.ID, target.checked)}
                                                    />
                                                    <span></span> 
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default IsLive