import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getEmailTemplateUrl                                	= '/api/admin/golfClub/getEmailTemplate';
const getAdminEmailTemplateUrl                           	= '/api/admin/golfClub/getAdminEmailTemplate';
const postEmailTemplateUrl                               	= '/api/admin/golfClub/postEmailTemplate';
const postTeamUrl                                        	= `/api/admin/golfClub/postTeam`;
const getTeamUrl                                      		= '/api/admin/golfClub/getTeam';
const getTeamBySelectionTypeUrl                             = (selectionType) => `/api/admin/golfClub/getTeamBySelectionType/${selectionType}`;
const getLimitedPlayerUrl                                   = (page, limit) => `/api/admin/golfClub/getLimitedPlayer/${page}/${limit}`;
const getPlayerUrl                                          = '/api/admin/golfClub/getPlayer';
const postPlayerUrl                                        	= `/api/admin/golfClub/postPlayer`;
const getCategoryUrl                                        = '/api/admin/golfClub/getCategory';
const postCategoryUrl                                       = `/api/admin/golfClub/postCategory`;

export const getEmailTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getEmailTemplateUrl,
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdminEmailTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getAdminEmailTemplateUrl,
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postEmailTemplate = async (templateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postEmailTemplateUrl,
        { templateDetails },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getLimitedPlayer = async (page, limit) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getLimitedPlayerUrl(page, limit),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTeam = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getTeamUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTeamBySelectionType = async (selectionType) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getTeamBySelectionTypeUrl(selectionType),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCategory = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCategoryUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTeam = async (teamDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postTeamUrl,
        { teamDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
export const postPlayer = async (playerDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postPlayerUrl,
        { playerDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
export const postCategory = async (categoryDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCategoryUrl,
        { categoryDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};