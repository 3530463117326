import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const postDraftIndexUrl                                         = `/api/admin/draft/postDraftIndex`;
const getDraftTeamListUrl                                       = '/api/admin/draft/getDraftTeamList';
const deleteDraftIndexUrl                                       = `/api/admin/draft/deleteDraftIndex`;
const getDraftTeamByRoundUrl                                    = (round) => `/api/admin/draft/getDraftTeamByRound/${round}`;

const addDraftSequenceUrl                                         = `/api/admin/draft/addDraftSequence`;
const updateDraftSequenceUrl                                      = `/api/admin/draft/updateDraftSequence`;


export const getDraftTeamList = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getDraftTeamListUrl,
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postDraftIndex = async (teamDraftDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postDraftIndexUrl,
        { teamDraftDetails },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteDraftIndex = async (draftDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosPost(
        deleteDraftIndexUrl,
        { draftDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getDraftTeamByRound = async (round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getDraftTeamByRoundUrl(round),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addDraftSequence = async (draftSequence) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        addDraftSequenceUrl,
        { draftSequence },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateDraftSequence = async (draftSequence) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        updateDraftSequenceUrl,
        { draftSequence },
      	{ headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};