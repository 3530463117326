import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import defaultUserImg from '../../assets/images/user.jpg'
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import { getCategory, postCategory } from "../api/golfClubAPI";

const Category = (props) => {

    const [categoryDetails,     setCategoryDetails]     = useState({});
    const [categoryList,        setCategoryList]        = useState({});
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [cropperData,         setCropperData]         = useState(null);
    const [userPhotoBlob,       setUserPhotoBlob]       = useState(null);

    useEffect(() => {
        props.title("Category", "category")   
        fetchCategory();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setCategoryDetails({});
    }, [rightSideDetails]);

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [categoryList]);

    const fetchCategory = async() => {
        try {
            const response = await getCategory()
            isInit ? setIsInit(false) : $('#tblCategory').KTDatatable().destroy(); 
            setCategoryList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fillDataTable = () =>{
        const dataTable = $('#tblCategory').KTDatatable({
            data: {
                saveState: false,
                source: categoryList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'DisplayName',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                <div class="kt-badge kt-badge--xl kt-badge--${row.Name == 'Gold' ? 'gold' : row.Name == 'Bronze' ? 'bronze' :'silver'}">  ${row.Name.substring(0, 1)}</div>
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'MinHandicap',
                    title: 'Min Handicap',
                    width: 100,
                },
                {
                    field: 'MaxHandicap',
                    title: 'Max Handicap',
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblCategory .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setCategoryDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }

    
    const updateCategoryDetails = (field, value) => {
        setCategoryDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    
    const addCategoryHandler = async () => {

        const btn = $('#btnSave');
        var form = $('#add-category-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },   
                txtDisplayName: {
                    required: true,
                },    
                txtMinHandicap: {
                    required: true,
                    number: true
                },         
                txtMaxHandicap: {
                    required: true,
                    number: true
                },         
            },
            messages: {
                txtName: {
                    required: "Please enter Name"
                },
                txtMaxHandicap: {
                    required: "Please enter max handicap"
                },
                txtMinHandicap: {
                    required: "Please enter min handicap"
                },
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {

            await postCategory(categoryDetails);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchCategory()
            if (categoryDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Category updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    icon: 'success',
                    titleText: 'Category added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Category..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {categoryList.length < 3 &&
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Category</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblCategory" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="Category Details" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-category-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={categoryDetails.Name || ''}
                                                        onChange={(event) => updateCategoryDetails('Name', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Min Handicap <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtMinHandicap" 
                                                        name="txtMinHandicap" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={categoryDetails.MinHandicap !== null && categoryDetails.MinHandicap !== undefined ? categoryDetails.MinHandicap : ''}
                                                        onChange={(event) => updateCategoryDetails('MinHandicap', event.target.value)} 
                                                    />
                                                </div>
                                            </div>  
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Max Handicap <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtMaxHandicap" 
                                                        name="txtMaxHandicap" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={categoryDetails.MaxHandicap || ''}
                                                        onChange={(event) => updateCategoryDetails('MaxHandicap', event.target.value)} 
                                                    />
                                                </div>
                                            </div>  
                                        </div>
                                    </form>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCategoryHandler}>Save</button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category