import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getTeam, postTeam } from "../api/golfClubAPI";
import defaultUserImg from '../../assets/images/user.jpg'
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";

const Team = (props) => {

    const [teamDetails,         setTeamDetails]         = useState({Color: '#4169E1', Active: 'Y', IsOwnerPlaying: 'Y'});
    const [teamList,            setTeamList]            = useState([]);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [cropperData,         setCropperData]         = useState(null);
    const [userPhotoBlob,       setUserPhotoBlob]       = useState(null);

    useEffect(() => {
        props.title("Teams", "teams")   
        fetchTeam();
    }, []);

    useEffect(() => {
        console.log(teamDetails)
    }, [teamDetails])

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setTeamDetails({Color: '#4169E1', Active: 'Y', IsOwnerPlaying: 'Y'});
    }, [rightSideDetails]);

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [teamList]);

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setUserPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    }
    
    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setUserPhotoBlob(null)
	}

    const fetchTeam = async() => {
        try {
            const response = await getTeam()
            isInit ? setIsInit(false) : $('#tblTeam').KTDatatable().destroy(); 
            setTeamList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    
    const fillDataTable = () =>{
        const dataTable = $('#tblTeam').KTDatatable({
            data: {
                saveState: false,
                source: teamList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'DisplayName',
                    title: 'Teams',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" style="background-color: ${row.HighColor}">  ${row.DisplayName.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.DisplayName} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'IsOwnerPlaying',
                    title: 'Owner Playing',
                    template: function (row) {
                        if (row.IsOwnerPlaying == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 100,
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTeam .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setTeamDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }

    
    const updateTeamDetails = (field, value) => {
        setTeamDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const addTeamHandler = async () => {

        const btn = $('#btnSave');
        var form = $('#add-team-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },   
                txtDisplayName: {
                    required: true,
                },    
                txtLowColor: {
                    required: true,
                },    
                txtHighColor: {
                    required: true,
                },    
                txtIndex: {
                    required: true,
                    number: true
                },         
            },
            messages: {
                txtName: {
                    required: "Please enter Full Name"
                },
                txtDisplayName: {
                    required: "Please enter display name"
                },
                txtLowColor: {
                    required: "Please enter low color"
                },
                txtHighColor: {
                    required: "Please enter high color"
                },
                txtIndex: {
                    required: "Please enter index"
                },
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let logo = '';
            if (userPhotoBlob) {
                const res = await uploadPublicFile(userPhotoBlob, 'Content');
                logo = res.fileName;
            }else{
                if(teamDetails.Logo) logo = teamDetails.Logo.split('/Content/')[1];
            }

            await postTeam({...teamDetails, Logo: logo});
            helper.StopProcessing(btn);
            setUserPhotoBlob(null)
            closeDrawer();
            fetchTeam()
            if (teamDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Team updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    icon: 'success',
                    titleText: 'Team added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Team..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Team</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblTeam" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="Team Details" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-team-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Full Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={teamDetails.Name || ''}
                                                        onChange={(event) => updateTeamDetails('Name', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Display Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtDisplayName" 
                                                        name="txtDisplayName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={teamDetails.DisplayName || ''}
                                                        onChange={(event) => updateTeamDetails('DisplayName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Notional Purse <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtWallet" 
                                                        name="txtWallet" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={teamDetails.Wallet || ''}
                                                        onChange={(event) => updateTeamDetails('Wallet', event.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Low Color <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <input
                                                        id="txtLowColor" 
                                                        name="txtLowColor" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={teamDetails.LowColor || ''}
                                                        onChange={(event) => updateTeamDetails('LowColor', event.target.value)}               
                                                    />		
                                                </div>
                                                <div className="col-1">
                                                    {(teamDetails.LowColor) && (
                                                        <div className="border color-box" style={{ backgroundColor: teamDetails.LowColor, height: '40px', width: '40px' }}></div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">High Color <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <input
                                                        id="txtHighColor" 
                                                        name="txtHighColor" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={teamDetails.HighColor || ''}
                                                        onChange={(event) => updateTeamDetails('HighColor', event.target.value)}               
                                                    />		
                                                </div>
                                                <div className="col-1">
                                                    {(teamDetails.HighColor) && (
                                                        <div className="border color-box" style={{ backgroundColor: teamDetails.HighColor, height: '40px', width: '40px' }}></div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Logo </label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div className="kt-avatar kt-avatar--outline kt-avatar--changed" id="kt_user_add_avatar">
                                                        <div className="kt-avatar__holder" 
                                                            style={{ backgroundImage : `url( ${userPhotoBlob ? URL.createObjectURL(userPhotoBlob): teamDetails.Logo ? teamDetails.Logo : defaultUserImg})` ,width: "120px", height:"120px" } }>    
                                                        </div>
                                                        <label 
                                                            className="kt-avatar__upload" 
                                                            data-toggle="kt-tooltip"  
                                                            data-original-title="Change avatar"
                                                            onClick ={openCropper}
                                                        >
                                                            <i className="fa fa-pen"></i>
                                                        </label>
                                                        {(!!userPhotoBlob || teamDetails.Photo) && (
                                                            <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Index <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtIndex" 
                                                        name="txtIndex" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={teamDetails.Index || ''}
                                                        onChange={(event) => updateTeamDetails('Index', event.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Owner Playing</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkIsOwnerPlaying" 
                                                                name="chkIsOwnerPlaying" 
                                                                type="checkbox" 
                                                                defaultChecked={teamDetails.IsOwnerPlaying == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updateTeamDetails('IsOwnerPlaying', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>     
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Active</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkActive" 
                                                                name="chkActive" 
                                                                type="checkbox" 
                                                                defaultChecked={teamDetails.Active == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updateTeamDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>     
                                        </div>
                                    </form>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addTeamHandler}>Save</button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Team;