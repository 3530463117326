import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getTeam } from "../api/golfClubAPI";
import defaultUserImg from '../../assets/images/user.jpg'
import { addOwnerAccess, deleteOwnerAccess, getOwnerAccessByTeamID } from "../api/ownerAccessAPI";

const OwnerAccess = (props) => {

    const [teamList,            setTeamList]            = useState([]);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [selectedTeam,        setSelectedTeam]        = useState(null);
    const [ownerDetails,        setOwnerDetails]        = useState({})
    const [ownerList,           setOwnerList]           = useState([]);

    useEffect(() => {
        props.title("Owner Access", "owner-access")   
        fetchTeam();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setSelectedTeam(null);
    }, [rightSideDetails]);

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [teamList]);

    const updateownerDetails = (field, value) => {   
        setOwnerDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const fillDataTable = () =>{
        const dataTable = $('#tblTeam').KTDatatable({
            data: {
                saveState: false,
                source: teamList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'DisplayName',
                    title: 'Teams',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" style="background-color: ${row.HighColor}">  ${row.DisplayName.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.DisplayName} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'IsOwnerPlaying',
                    title: 'Owner Playing',
                    template: function (row) {
                        if (row.IsOwnerPlaying == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 100,
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTeam .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setSelectedTeam(dataTable.dataSet[index]);  
                fetchOwnerAccessByTeamID(dataTable.dataSet[index].ID)
                setRightSideDetails(true);           
            }                
        });
    }

    const fetchTeam = async() => {
        try {
            const response = await getTeam()
            isInit ? setIsInit(false) : $('#tblTeam').KTDatatable().destroy(); 
            setTeamList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const fetchOwnerAccessByTeamID = async(TeamID) => {
        try {
            const owner = await getOwnerAccessByTeamID(TeamID)
            setOwnerList(owner);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const deleteHandler = async (ID) => {
        try {
            await deleteOwnerAccess(ID)
            fetchOwnerAccessByTeamID(selectedTeam.ID)
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'owner deleted!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const AddOwnerAccessHandler = async (e) => {
        e.preventDefault()
        const btn = $('#add-owner-access-btn');
        var form = $('#add-owner-access-form');

        form.validate({
            rules: {
                userName: {
                    required: true
                },          
                email: {
                    required: true
                },          
            },
            messages: {
                userName: {
                    required: "Please enter username"
                },
                email: {
                    required: "Please enter email"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await addOwnerAccess({...ownerDetails, TeamID: selectedTeam.ID});
            setOwnerDetails({})
            helper.StopProcessing(btn);
            fetchOwnerAccessByTeamID(selectedTeam.ID)
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Team..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblTeam" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && 
                            <RightSideDetails title="Owner Access" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <div className="kt-widget kt-widget--user-profile-3">
                                            <div className="kt-widget__top">
                                                {selectedTeam.Logo ? (
                                                    <img className="kt-widget__pic kt-widget__pic" src={selectedTeam.Logo} alt="user-profile"/>
                                                ) : (

                                                    <div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest" style={{backgroundColor: selectedTeam.HighColor}}>
                                                        {selectedTeam.DisplayName && selectedTeam.DisplayName.slice(0,1)}
                                                    </div>
                                                )}
                                                <div className="kt-widget__content">
                                                    <div className="row align-items-center">
                                                        <div className="col-5">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {selectedTeam.DisplayName}
                                                                </span>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="col-3">
                                                            <span className='font-weight500'>Owner Playing</span> : <span className={`kt-badge kt-badge--${selectedTeam.IsOwnerPlaying == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{selectedTeam.IsOwnerPlaying == 'Y' ? 'Yes' : 'No'}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-prtlet--mobile">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-owner-access-form" onSubmit={AddOwnerAccessHandler}>
                                            <div className="form-group row">
                                                <div className="col-4">
                                                    <input
                                                        id="userName"
                                                        name="userName"
                                                        type='text'
                                                        placeholder="Name"
                                                        className = "form-control"
                                                        value={ownerDetails?.UserName || ''}
                                                        onChange={(e) => updateownerDetails('UserName', e.target.value)} 
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <input 
                                                        id="email" 
                                                        name="email"
                                                        placeholder="Email"
                                                        type="text" 
                                                        className="form-control"
                                                        value={ownerDetails?.Email || ''}
                                                        onChange={(e) => updateownerDetails('Email', e.target.value)} 
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <button className="btn btn-brand margin-l0" type="submit" id='add-owner-access-btn'>
                                                        <i className="la la-plus"></i>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <table id="table-block" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th> Name </th>
                                                    <th> Email </th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {ownerList && ownerList.length > 0 ? (
                                                    ownerList.map((o, i) => (
                                                        <tr key={i}>
                                                            <td>{o.UserName}</td>
                                                            <td>{o.Email}</td>
                                                            <td className="text-center">
                                                                <button 
                                                                    type="button"                    
                                                                    onClick={() => deleteHandler(o.ID)}
                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                </button>  
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={4}> 
                                                            <div className="padding-10"> 
                                                                <h5 className="text-center text-muted"> No Owner Access...! </h5> 
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </RightSideDetails>
                        }
                                    
                    </div>
                </div>
            </div>
        </>
    )

}

export default OwnerAccess;